import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=AutoComplete wrap=function",
        "componentName": "AutoComplete",
        "wrap": "function"
      }}>{`() => {
  const [selectedValue, setSelectedValue] = React.useState('');

  return (
    <>
      <Paragraph>Selected value: {selectedValue}</Paragraph>
      <AutoComplete
        suggestions={['One', 'Two', 'Three']}
        onChange={setSelectedValue}
        id="main"
        label="Your favorite number"
        value={selectedValue}
      />
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="AutoComplete" mdxType="Props" />
    <h2 {...{
      "id": "forceselection",
      "style": {
        "position": "relative"
      }
    }}>{`ForceSelection`}</h2>
    <p>{`Make sure a user selects one of the selections`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [selectedValue, setSelectedValue] = React.useState('');

  return (
    <>
      <Paragraph>Selected value: {selectedValue}</Paragraph>
      <AutoComplete
        suggestions={['One', 'Two', 'Three']}
        onChange={setSelectedValue}
        id="onChange"
        label="Your favorite number"
        forceSelection
      />
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "suggestions",
      "style": {
        "position": "relative"
      }
    }}>{`Suggestions`}</h2>
    <p><inlineCode parentName="p">{`default: []`}</inlineCode></p>
    <p><inlineCode parentName="p">{`suggestions`}</inlineCode>{` is a list of strings that may be suggested to the user, based on
their input.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [selectedValue, setSelectedValue] = React.useState('');

  return (
    <>
      <AutoComplete
        suggestions={['One', 'Two', 'Three']}
        onChange={setSelectedValue}
        id="suggestions"
        label="Your favorite number"
        value={selectedValue}
      />
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "onchange",
      "style": {
        "position": "relative"
      }
    }}>{`OnChange`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p><inlineCode parentName="p">{`onChange`}</inlineCode>{` is triggered on every change made by the user, so when the user types
or when user selectes a suggested value, either by keyboard or mouse.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [selectedValue, setSelectedValue] = React.useState('');

  return (
    <>
      <Paragraph>Selected value: {selectedValue}</Paragraph>
      <AutoComplete
        suggestions={['One', 'Two', 'Three']}
        onChange={setSelectedValue}
        id="onChange"
        label="Your favorite number"
        value={selectedValue}
      />
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "oninputchange",
      "style": {
        "position": "relative"
      }
    }}>{`OnInputChange`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`In contrast to `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`, `}<inlineCode parentName="p">{`onInputChange`}</inlineCode>{` is only triggered when the user types
in the input field. The reason this is separated, is to reduce potential API
calls when the suggestions are fetched from an API (e.g. streets in an
addressform).`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` when you supply the `}<inlineCode parentName="p">{`onInputChange`}</inlineCode>{` property, the component will no
longer automagically filter suggestions on typing. We assume that, since you're
using the value of the input yourself, you will provide the updated suggestions
to the `}<inlineCode parentName="p">{`suggestions`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [selectedValue, setSelectedValue] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([]);

  const fakeApiCall = term => {
    const newSuggestions = ['One', 'Two', 'Three'].filter(term =>
      term.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSuggestions(newSuggestions);
  };

  React.useEffect(() => {
    fakeApiCall(searchTerm);
  }, [searchTerm]);

  return (
    <>
      <Paragraph>Selected value: {selectedValue}</Paragraph>
      <Paragraph>Search term for API: {searchTerm}</Paragraph>
      <AutoComplete
        suggestions={suggestions}
        onChange={setSelectedValue}
        onInputChange={setSearchTerm}
        id="onInputChange"
        label="Your favorite number"
        value={selectedValue}
      />
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "loading",
      "style": {
        "position": "relative"
      }
    }}>{`Loading`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`To indicate to a user that, based on their input an API call is being called,
you can set the `}<inlineCode parentName="p">{`loading`}</inlineCode>{` prop to true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [selectedValue, setSelectedValue] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fakeApiCall = term => {
    setIsLoading(true);
    setTimeout(() => {
      const newSuggestions = ['One', 'Two', 'Three'].filter(term =>
        term.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSuggestions(newSuggestions);
      setIsLoading(false);
    }, 500);
  };

  React.useEffect(() => {
    fakeApiCall(searchTerm);
  }, [searchTerm]);

  return (
    <>
      <Paragraph>Selected value: {selectedValue}</Paragraph>
      <Paragraph>Search term for API: {searchTerm}</Paragraph>
      <AutoComplete
        suggestions={suggestions}
        onChange={setSelectedValue}
        onInputChange={setSearchTerm}
        loading={isLoading}
        id="loading"
        label="Your favorite number"
        value={selectedValue}
      />
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "iconleft",
      "style": {
        "position": "relative"
      }
    }}>{`IconLeft`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`For further illustration purposes, you can provide an `}<inlineCode parentName="p">{`iconLeft`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [selectedValue, setSelectedValue] = React.useState('');

  return (
    <>
      <AutoComplete
        suggestions={['One', 'Two', 'Three']}
        onChange={setSelectedValue}
        iconLeft={Search}
        id="iconLeft"
        label="Your favorite number"
        value={selectedValue}
      />
    </>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "label-props",
      "style": {
        "position": "relative"
      }
    }}>{`Label Props`}</h3>
    <p>{`As with other input elements, `}<inlineCode parentName="p">{`AutoComplete`}</inlineCode>{` also has a wide range of extra
properties to convey even more meaning.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [selectedValue, setSelectedValue] = React.useState('');

  return (
    <>
      <AutoComplete
        suggestions={['One', 'Two', 'Three']}
        onChange={setSelectedValue}
        label="What's your address?"
        action={<LinkText>Where can I find it?</LinkText>}
        message="We need your data"
        optionalLabel="Optional"
        id="labelProps"
        value={selectedValue}
      />
    </>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      